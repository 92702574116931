import React, { useContext, useEffect, useState } from 'react';
import { EntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import { FullPageLoadingSpinner } from '@property-folders/components/dragged-components/FullPageLoadingSpinner';
import { Button, Card, Container, Form } from 'react-bootstrap';
import { AjaxPhp, GetEntityBillingResponse } from '@property-folders/common/util/ajaxPhp';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { Maybe } from '@property-folders/contract';
import { CardDetails } from '@property-folders/components/dragged-components/payment/CardDetails';
import { CardOnRecord } from '@property-folders/components/dragged-components/payment/CardOnRecord';
import { AuthApi } from '@property-folders/common/client-api/auth';
import {
  HumanTimestampText,
  SupportedRelativeTimeFormatUnit
} from '@property-folders/components/dragged-components/HumanTimestamp';

export function BillingSettings() {
  const [billingEmail, setBillingEmail] = useState('');
  const { entityPhpInfo } = useContext(EntitySettingsContext);
  const [errors, setErrors] = useState<{ billingEmail: boolean }>({ billingEmail: false });
  const [loading, setLoading] = useState(true);
  const [latest, setLatestData] = useState<Maybe<GetEntityBillingResponse>>(undefined);
  const [titleCardDetails, setTitleCardDetails] = useState<CardDetails | null>(null);
  const [titleCardValid, setTitleCardValid] = useState<boolean>(false);
  const { data: session } = AuthApi.useGetAgentSessionInfo();
  const entityId = entityPhpInfo?.entityId;
  const entity = session?.entities.find(e => e.entityId === entityId);
  const hasSaSubscription = !!entity?.hasSaSubscription;
  const [subscriptionCardDetails, setSubscriptionCardDetails] = useState<CardDetails | null>(null);
  const [subscriptionCardValid, setSubscriptionCardValid] = useState<boolean>(false);
  const [updatingSubscriptionCard, setUpdatingSubscriptionCard] = useState<number | null>(null);
  const [updatingTitleCardDetails, setUpdatingTitleCardDetails] = useState<boolean>(false);
  console.log('card details');

  const onSave = async () => {
    const entityId = entityPhpInfo?.entityId;
    if (!entityId) {
      return;
    }

    await AjaxPhp.updateEntitySetting({
      entityId,
      setting: 'billing-email',
      value: billingEmail as string
    });
  };

  const onSaveTitleCard = async () => {
    if (titleCardDetails && titleCardValid && titleCardDetails.number && entityId) {
      await AjaxPhp.updateTitlePurchaseBilling({
        card: titleCardDetails,
        entityId
      });
    }

    setUpdatingTitleCardDetails(false);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const entityId = entityPhpInfo?.entityId;
    if (!entityId) return;

    AjaxPhp.getEntityBilling({ entityId, signal: abortController.signal }).then(result => {
      if (result === undefined) {
        return;
      }

      setLatestData(structuredClone(result));
    })
      .catch(console.error)
      .finally(() => setLoading(false));

    return () => {
      abortController.abort();
    };
  }, [entityPhpInfo?.entityId]);

  if (loading || !latest) {
    return <FullPageLoadingSpinner lightMode={true} />;
  }

  const getProductName = (product: string, state?: string) => product.toLowerCase() === 'subscription forms' && state
    ? `${product} - ${state.toUpperCase()}`
    : product;

  const getSubscriptionPriceText = (monthly: boolean, fee: string, trial: boolean) => trial
    ? 'Trial'
    : `$${fee} (${monthly ? 'Monthly' : 'Annually'})`;

  const saveSubscriptionPayment = async () => {
    if (!updatingSubscriptionCard || !entityPhpInfo?.entityId || !subscriptionCardDetails) {
      console.log('no save', updatingSubscriptionCard, entityPhpInfo?.entityId, subscriptionCardDetails);
      return;
    }

    await AjaxPhp.updateSubscriptionPaymentDetails({
      productId: updatingSubscriptionCard,
      card: subscriptionCardDetails,
      entityId: entityPhpInfo.entityId
    });

    setUpdatingSubscriptionCard(null);
  };

  return <Container fluid={false} className='h-100 position-relative d-flex flex-column flex-start g-0'>
    <Card>
      <Card.Header>
        <div className='title d-flex w-100 align-items-center justify-content-between'>
          <span className='fs-3'>Billing</span>
          <div className='d-flex flex-column align-items-end'>
            <AsyncButton disabled={errors.billingEmail} onClick={onSave}>Save</AsyncButton>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <Form.Group className='mb-3 col-12 col-md-6'>
          <Form.FloatingLabel label='Billing Email' className='common-label'>
            <Form.Control
              type='text'
              name='billingEmail'
              placeholder=''
              value={latest?.billingEmail}
              onChange={e => {
                setBillingEmail(e.currentTarget.value);
                setLatestData({
                  ...latest,
                  billingEmail: e.currentTarget.value
                });
              }}
              onBlur={() => {
                const { valid, canonical } = canonicalisers.email(billingEmail);
                setBillingEmail(canonical as string);
                setErrors(e => ({ ...e, billingEmail: !valid }));
              }}
              isInvalid={Boolean(errors.billingEmail)}
            />
          </Form.FloatingLabel>
        </Form.Group>

        {hasSaSubscription && <>
          <hr />
          <div className='d-flex mt-2'>
            <h3>Title Purchases</h3>
            {!updatingTitleCardDetails && <AsyncButton className='ms-auto' onClick={() => setUpdatingTitleCardDetails(true)}>Update</AsyncButton>}
            {updatingTitleCardDetails && <AsyncButton className='ms-auto' disabled={!titleCardValid} onClick={onSaveTitleCard}>Save</AsyncButton>}
          </div>
          <p>Users can purchase a variety of products from Land Services SA from directly within documents.
            By default, users will be prompted to enter their payment details each time, when making a purchase.
            Alternatively, you can set up agency-wide payment details here.
          </p>
          {latest.titlePayment.cardType && !updatingTitleCardDetails
            ? <>
              <CardOnRecord
                cardType={latest.titlePayment.cardType}
                name={latest.titlePayment.cardName}
                lastFour={latest.titlePayment.lastFour}
                expiry={`${latest.titlePayment.ccMonth}/${latest.titlePayment.ccYear}`}
              />
            </>
            : !updatingTitleCardDetails
              ? <p>No payment on record</p>
              : <>
                <CardDetails
                  setValue={setTitleCardDetails}
                  setValid={setTitleCardValid}
                />
              </>
          }
        </>}

        <hr />

        <h3>Subscription{latest.subscriptions.length > 1 ? 's' : ''}</h3>
        {latest.subscriptions.map(s => <>
          <div className='d-flex mt-2'>
            <h4>{getProductName(s.product, s.stateName)}</h4>
            <h4 className='ms-auto'>{getSubscriptionPriceText(s.monthly, s.fee, s.trial)}</h4>
          </div>

          <div className='mt-1 d-flex'>
            <h5>Payment</h5>
            {s.monthly && s.productId !== updatingSubscriptionCard && (
              <Button className='ms-auto' onClick={() => setUpdatingSubscriptionCard(s.productId)}>Update</Button>
            )}
            {s.monthly && s.productId === updatingSubscriptionCard && (
              <AsyncButton className='ms-auto' disabled={!subscriptionCardValid}
                onClick={saveSubscriptionPayment}>Save</AsyncButton>
            )}
          </div>
          {s.monthly
            ? <>
              {s.payment && s.payment.paymentId && updatingSubscriptionCard !== s.productId
                ? <CardOnRecord
                  cardType={s.payment.cardType}
                  name={s.payment.cardHolder}
                  lastFour={s.payment.lastFour}
                  expiry={`${s.payment.ccMonth}/${s.payment.ccYear}`}
                />
                : updatingSubscriptionCard !== s.productId ? <p>No payment on record</p> :
                  <CardDetails setValue={setSubscriptionCardDetails} setValid={setSubscriptionCardValid} />}
            </>
            : <p>Invoiced directly from reaforms</p>
          }

          {s.invoiceHistory && s.invoiceHistory.length > 0 && <>
            <h5 className='mt-1'>Subscription History</h5>
            <table class='table'>
              <thead>
                <th>Date</th>
                <th>Description</th>
                <th>Action</th>
              </thead>
              <tbody>
                {s.invoiceHistory.sort((a, b) => a.dateStamp.localeCompare(b.dateStamp)).map(h => <>
                  <tr>
                    <td><HumanTimestampText timestamp={new Date(h.dateStamp)}
                      maxInterval={SupportedRelativeTimeFormatUnit.day} /></td>
                    <td>{h.description}</td>
                    <td>{h.action === 2 && <Button>Download</Button>}</td>
                  </tr>
                </>)}
              </tbody>
            </table>
          </>}

          <hr />
        </>)}

      </Card.Body>
    </Card>
  </Container>;
}
